import $ from "jquery";
import { createPopper } from '@popperjs/core';
export const Tooltip = () => {

  $(document).ready(function () {

    const button = document.querySelector('#button');
    const tooltip = document.querySelector('#tooltip');

    const popperInstance = createPopper(button, tooltip, {
        placement: 'right',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });


      function show() {
        tooltip.setAttribute('data-show', '');
        $('.ss-main').css('z-index', '-1');
        // We need to tell Popper to update the tooltip position
        // after we show the tooltip, otherwise it will be incorrect
        popperInstance.update();
      }
      
      function hide() {
        tooltip.removeAttribute('data-show');
        $('.ss-main').css('z-index', '1');
      }
      
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];
      
      showEvents.forEach((event) => {
        if(button) {
          button.addEventListener(event, show);
        }
      });
      
      hideEvents.forEach((event) => {
        if(button) {
          button.addEventListener(event, hide);
        }
      });


      const copyTooltip = $('.copy-popup');
      const copyBtn = $('.copy-this');

      copyBtn.on('click', function() {
        copyTooltip.addClass('visible');
        setTimeout(function() {
          copyTooltip.removeClass('visible');
        }, 2000);
      });
});

};

